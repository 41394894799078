import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router';

import NavigationDrawer from "./Components/NavigationDrawer";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Products from "./Components/Products";

// import 'semantic-ui-css/semantic.min.css';
import "./semantic-ui-css/semantic.css";
import "./semantic-ui-css/semantic.min.css";
import "./App.css";


class App extends Component {
  
  state = {
    isSideMenuOpen: false,
  }
  
  openSideMenu = () => {
    this.setState({isSideMenuOpen: true});
  }
  
  closeSideMenu = () => {
    this.setState({isSideMenuOpen: false});
  }
  
  render = () => {
    return (
      <div className="App">
        <NavigationDrawer
          openSideMenu={this.openSideMenu}
          closeSideMenu={this.closeSideMenu}
          isSideMenuOpen={this.state.isSideMenuOpen}
        >
          <div className='appBody'>
            <Header openSideMenu={this.openSideMenu}/>
            <Switch>
              <Route
                exact
                path='/'
                render={(props) => {props.history.replace('/home')}}
              />
              <Route
                path="/home"
                component={Home}
              />
              <Route
                path="/products"
                component={Products}
              />
            </Switch>
            <Footer/>
          </div>
        </NavigationDrawer>
      </div>
    );
  }
} export default withRouter(App);
