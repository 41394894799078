import React, {Component} from "react";
import {withRouter} from 'react-router';
import moment from "moment";

class Footer extends Component {
  render = () => {
    return(
      <div className="footer">
        <span>&copy; {new moment().format("YYYY")} - B MED Industrial Supply LLC</span>
      </div>
    )
  }
}

export default withRouter(Footer);
