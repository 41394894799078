import React, {Component} from "react";
import {withRouter} from 'react-router';
// import { Button } from '@progress/kendo-react-buttons';

class Products extends Component {
  render = () => {
    return(
      <div className="productsContainer">
        <div className="productsHeader">
          <span>B MED Products - Cage Code: 844V1 - GSA: 47QMCA22D0016</span>
        </div>
        
        <div className="products">
          
          <div className="productsRow">
            
            {/* BMed Capital Equipment */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Capital Equipment</span>
              </div>
              <div className="prodContent">
                <img
                  id="capitalEquipment"
                  src="./images/bmd-tm1-thumb-2x.png"
                  alt="CapitalEquipment"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/BMD-TM1.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
  
            {/* BMed Storage Solutions */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Storage Solutions</span>
              </div>
              <div className="prodContent">
                <img
                  id="storageSolutions"
                  src="./images/bmd-storage-thumb-2x.png"
                  alt="StorageSolutions"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/BMD-Storage.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
          </div>
  
          <div className="productsRow">
  
            {/* BMed Field Ready Tools */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Field-Ready Tools</span>
              </div>
              <div className="prodContent">
                <img
                  id="fieldReadyTools"
                  src="./images/bmd-impact-thumb-2x.png"
                  alt="FieldReadyTools"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/BMD-Impact.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
  
            {/* BMed Installation */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Turnkey Installation</span>
              </div>
              <div className="prodContent">
                <img
                  id="turnkeyInstall"
                  src="./images/bmd-install-2x.png"
                  alt="TurnkeyInstall"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/BMD-Installs.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
          </div>
  
          <div className="productsRow">
  
            {/* PS Natural */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Peat Sorb - Natural</span>
              </div>
              <div className="prodContent">
                <img
                  id="psNatural"
                  src="./images/ps-natural-2x.png"
                  alt="PSNatural"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/PeatSorb-NaturalAbsorbent.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
  
            {/* PS Video */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Peat Sorb - Video</span>
              </div>
              <div className="prodContent">
                <img
                  id="psVideo"
                  src="./images/ps-video-2x.png"
                  alt="PSVideo"
                  onClick={() => {
                    window.open("https://www.youtube.com/watch?v=N4vPW2JJsKQ", "_blank")
                  }}
                />
              </div>
            </div>
          </div>
  
          <div className="productsRow">
  
            {/* PS Green */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Peat Sorb - Green</span>
              </div>
              <div className="prodContent">
                <img
                  id="psGreen"
                  src="./images/ps-green-2x.png"
                  alt="PSGreen"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/PeatSorb-Green.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
  
            {/* PS Coast Guard */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Peat Sorb - Coast Guard</span>
              </div>
              <div className="prodContent">
                <img
                  id="psCoastguard"
                  src="./images/ps-coastguard copy-2x.png"
                  alt="PSCoastguard"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/PeatSorb-CoastGuard.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
          </div>
  
          <div className="productsRow">
  
            {/* PS EPA */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Peat Sorb - EPA</span>
              </div>
              <div className="prodContent">
                <img
                  id="psEpa"
                  src="./images/ps-epa-2x.png"
                  alt="PSEpa"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/PeatSorb-EPA.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
  
            {/* PS Oceanography */}
            <div className="product">
              <div className="prodHeader">
                <span className="prodLabel">Peat Sorb - Oceanography</span>
              </div>
              <div className="prodContent">
                <img
                  id="psOceanography"
                  src="./images/ps-oceanography-2x.png"
                  alt="PSOceanography"
                  onClick={() => {
                    const html = `
                          <html>
                            <style>
                              html,
                              body {
                                padding: 0;
                                margin: 0;
                              }
                              iframe {
                                width: 100%;
                                height: 100%;
                                border: 0;
                              }
                            </style>
                            <body>
                              <iframe src="./pdfs/PeatSorb-Oceanography.pdf"></iframe>
                            </body>
                          </html>
                          `
                    const newWindow = window.open("about:blank");
                    newWindow.document.write(html);
                    newWindow.document.close();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Products);
