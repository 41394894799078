import React, {Component} from "react";
import {withRouter} from 'react-router';
import APIService from '../API';

import {Button, Form, Icon, Modal} from "semantic-ui-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintRoller, faFillDrip, faHandPointRight } from '@fortawesome/free-solid-svg-icons';

class Home extends Component {
  
  static defaultState = {
    company: "",
    name: "",
    phone: "",
    email: "",
    comment: "",
  }
  
  state = {
    ...Home.defaultState,
    isPostingForm: false,
    isSuccessfulPost: false,
    isFailedPost: false,
  }
  
  onSubmit = () => {
    this.setState({isPostingForm: true});
    return new Promise(resolve => window.grecaptcha.ready(resolve))
      .then(() => {
        console.log("Begin grecaptcha fetch")
        return window.grecaptcha.execute(APIService.captchaKey, {action: 'submit'})
      })
      .then((token) => {
        // return fetch() here
        console.log("Successful grecaptcha fetch, begin posting application")
        let {company, name, phone, email, comment} = this.state;
        let request = {
          company: company,
          name: name,
          phone: phone,
          email: email,
          comment: comment
        }
        return fetch(APIService.serverUrl + "/contact", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            grt: token
          },
          credentials: "include",
          body: JSON.stringify(request)
        })
      })
    
      .then(response => {
        console.log("First then promise");
        if (response.ok) {
          console.log("Good post, ", response)
          return response;
        } else {
          console.log("Bad post");
          throw "Posting Form Failed"
        }
      })
      .then(() => {
        console.log("Second then promise");
        this.toggleSuccessfulModal();
        this.setState({
          ...Home.defaultState
        })
      })
      .catch(err => {
        this.setState({
          errorCode: err
        })
        this.toggleFailedModal();
        console.log("Error: ", err)
      })
      .finally(() => {
        console.log("Final promise");
        this.setState({isPostingForm: false})
      });
  }
  
  toggleSuccessfulModal = () => {
    this.setState({isSuccessfulPost: !this.state.isSuccessfulPost})
  }
  
  toggleFailedModal = () => {
    this.setState({isFailedPost: !this.state.isFailedPost})
  }
  
  render = () => {
    return(
      <div className="home">
        <div className="information">
          
          <div id="products" className="section">
            <div className="sectionHeader">
              <div className="headerBarContainer">
                <span>Products & Services</span>
                <div className="headerBar"/>
              </div>
              <FontAwesomeIcon icon={faPaintRoller}/>
            </div>
            <div className="contentContainer">
              <span className="contentLabel">A Leading MRO Supplier</span>
              <span className="content">B MED Industrial Supply LLC is a leading MRO supplier of a wide variety of industry products and custom designed kitting for the military. Founded in 2004, B MED helps companies meet their MRO needs in a wide variety of business sectors including: banking, mining, automotive, as well as health care.</span>
              <span className="content" style={{marginTop: "13px"}}>B MED can help solve your facility issues too, ranging from floor coatings to turnkey capital equipment. Managing projects that range from $10,000 to over $1 million, B MED industrial supply handles every detail. Starting with acquisition and assembly, every project is followed through with complete installation.</span>
            </div>
          </div>
  
          <div id="about" className="section">
            <div className="sectionHeader">
              <div className="headerBarContainer">
                <span>B MED Industrial</span>
                <div className="headerBar"/>
              </div>
              <FontAwesomeIcon icon={faFillDrip}/>
            </div>
            <div className="contentContainer">
              <span className="contentLabel">About Us</span>
              <span className="content">The B MED team is a group of like-minded individuals that operates with a collective 150+ years of problem-solving experience to help businesses throughout the US and abroad. Contact B MED today! We can help you improve and enhance your business operations.</span>
            </div>
          </div>
  
          <div id="contact" className="section">
            <div className="sectionHeader">
              <div className="headerBarContainer">
                <span>Contact B MED</span>
                <div className="headerBar"/>
              </div>
              <FontAwesomeIcon icon={faHandPointRight}/>
            </div>
            <div className="contentContainer">
              <span className="contentLabel">
                Let us hear from you!
              </span>
              <Form>
                <Form.Field>
                  <input
                    value={this.state.company}
                    onChange={(e) => {
                      this.setState({company: e.target.value})
                    }}
                    placeholder='Company'
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({name: e.target.value})
                    }}
                    placeholder='Name'
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    value={this.state.phone}
                    onChange={(e) => {
                      this.setState({phone: e.target.value})
                    }}
                    placeholder='Phone'
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({email: e.target.value})
                    }}
                    placeholder='Email'
                  />
                </Form.Field>
                <Form.Field>
                  <textarea
                    value={this.state.comment}
                    rows={2}
                    onChange={(e) => {
                      this.setState({comment: e.target.value})
                    }}
                    placeholder='Comments'
                  />
                </Form.Field>
                <Button
                  disabled={this.state.isPostingForm}
                  loading={this.state.isPostingForm}
                  type='submit'
                  onClick={this.onSubmit}
                >
                  Send</Button>
              </Form>
            </div>
          </div>
        </div>
  
        <div className="imageContainer">
          <img src="./images/product1-photo-2x.png" alt="enclosed-machine"/>
          <img src="./images/product2-photo-2x.png" alt="table-machine"/>
          <img src="./images/product3-photo-2x.png" alt="small-tools"/>
          <img src="./images/product4-photo-2x.png" alt="truck-lift"/>
        </div>
  
        <Modal
          size='tiny'
          open={this.state.isSuccessfulPost}
        >
          <Modal.Header>Thank you for inquiring!</Modal.Header>
          <Modal.Content>
            <span>We will get in touch with you after we review your submitted information.</span>
            <div style={{marginTop: '10px', width: "100%"}}>
              <Button
                color='blue'
                size='tiny'
                onClick={this.toggleSuccessfulModal}
              >
                <Icon name='close'/>
                Close
              </Button>
            </div>
          </Modal.Content>
        </Modal>
  
        <Modal
          size='tiny'
          open={this.state.isFailedPost}
        >
          <Modal.Header>We have encountered an issue.</Modal.Header>
          <Modal.Content>
            <span>Please verify your information is correct.</span>
            <span>If issues persist please email your inquiry directly to <font style={{fontWeight: "700"}}>bmedindsupplyllc@gmail.com</font>.</span>
            <div style={{marginTop: '10px', width: "100%"}}>
              <Button
                color='red'
                size='tiny'
                onClick={this.toggleFailedModal}
              >
                <Icon name='close'/>
                Close
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default withRouter(Home);
