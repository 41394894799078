import React, {Component} from "react";
import {withRouter} from "react-router";

import {Menu, Sidebar, Divider, Icon} from "semantic-ui-react";

class NavigationDrawer extends Component {
  
  navToLink = (route) => {
    this.props.history.push(`/${route}`);
    this.props.closeSideMenu();
  }
  
  render = () => {
    return(
      <Sidebar.Pushable>
        <Sidebar
          borderless
          vertical
          as={Menu}
          id="menu"
          direction="right"
          animation='overlay'
          className="menuSidebar"
          onHide={() => this.props.closeSideMenu}
          visible={this.props.isSideMenuOpen}
        >
          <div className="sidebarMenu">
            <Icon
              name="close"
              link
              onClick={this.props.closeSideMenu}
            />
            <Divider/>
            
            <span onClick={() => this.navToLink('home')}>Home</span>
            <Divider/>
  
            <span onClick={() => this.navToLink('products')}>Products</span>
            <Divider/>
  
            <a href="/home#contact">
              Contact Us
            </a>
          </div>
        </Sidebar>

        <Sidebar.Pusher>
          {this.props.children}
        </Sidebar.Pusher>

      </Sidebar.Pushable>
    )
  }
}

export default withRouter(NavigationDrawer);
