import React, {Component} from "react";
import {withRouter} from 'react-router';

import {Icon} from "semantic-ui-react";

class Header extends Component {
  render = () => {
    return(
      <div className="header">
        <div className="mobileHeader">
          <img src="./images/bmed-logo.png" alt="bmed-logo"/>
          <Icon
            name="bars"
            link
            onClick={this.props.openSideMenu}
          />
        </div>
        
        <div className="desktopHeader">
          <div className="dHeaderContainer">
            <img id="badgeImg" src="./images/badge-desktop-2x.png" alt="badge"/>
            <Icon
              name="bars"
              link
              onClick={this.props.openSideMenu}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Header);
